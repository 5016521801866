/**********************************
    Generate breakpoints
	by https://css-tricks.com/snippets/sass/mixin-manage-breakpoints/
**********************************/

$breakpoints: (
	'xxs': 0px, // Old Mobile Portrait
	'xs' : 375px, // Mobile Portrait
	'sm' : 576px, // Mobile Landscape
	'md' : 768px, // Tablet Portrait
	'lg' : 992px, // Tablet Landscape
	'xl' : 1200px, // Desktop
	'xxl' : 1520px // Desktop Large
) !default;


@mixin bp($breakpoint) {
	@if map-has-key($breakpoints, $breakpoint) { // If the key exists in the map
		@media (min-width: map-get($breakpoints, $breakpoint)) {
			@content; // Prints a media query based on the value
		}
	} @else { // If the key doesn't exist in the map
		@warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. " + "Available breakpoints are: #{map-keys($breakpoints)}.";
	}
}


/**********************************
	Add custom media query
**********************************/

@mixin custom($screen) {
	@media (min-width: $screen+'px') {
		@content;
	}
}