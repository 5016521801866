/******************************************
	Background Colors
******************************************/

// .bg--color--primary { background-color: $color-primary }
// .bg--color--secondary { background-color: $color-secondary }
// .bg--color--tertiary { background-color: $color-tertiary }

.bg--color--white { background-color: $color-white }
.bg--color--black { background-color: $color-black }

.bg--color--grey1 { background-color: $color-grey-1 }
.bg--color--grey2 { background-color: $color-grey-2 }
// .bg--color--grey3 { background-color: $color-grey-3 }
// .bg--color--grey4 { background-color: $color-grey-4 }
// .bg--color--grey5 { background-color: $color-grey-5 }



/******************************************
	Text Colors
******************************************/

// .color--primary { color: $color-primary }
// .color--secondary { color: $color-secondary }
// .color--tertiary { color: $color-tertiary }

.color--white { color: $color-white }
.color--black { color: $color-black }

.color--grey1 { color: $color-grey-1 }
.color--grey2 { color: $color-grey-2 }
// .color--grey3 { color: $color-grey-3 }

// .bg-color--primary,
// .bg--color--secondary,
// .bg--color--tertiary,
// .bg--color--black {
// 	h1, h2, h3, h4, h5 p, li, td { color: $color-white }
// }



/******************************************
	General
******************************************/

html {
	overflow: hidden;
	-webkit-text-size-adjust: 100%;
	scroll-behavior: unset !important;
	background-color: $color-grey-1;

	@include bp(md) { overflow: auto; }

	&.html--legal { overflow: auto; }
}

body {
	overflow: hidden;
	// overflow-y: scroll;
	width: 100%;
	-webkit-overflow-scrolling: touch;
	background-color: $color-grey-1;

	@include bp(md) { overflow: auto; }

	&.body--legal { overflow: auto; }
}

.page {
	position: relative;
	overflow: hidden;
	margin: 0;
}

body, p, li, td {
	margin-top: 0;
	font-family: $font-body;
	color: $color-grey-2;
	font-size: 14px;
	line-height: 1.8;
	font-weight: 400;
	font-style: normal;
	letter-spacing: 0;
	@include bp(md) { font-size: 16px; }  
	// @include bp(xl) { font-size: 17px; }  
}

p, ul { 
	margin-bottom: 15px; 
	@include bp(lg) { margin-bottom: 20px; }  
	@include bp(xl) { margin-bottom: 30px; }
}

ul { margin-top: 0; }

h2 + ul, h2 + ol, 
h3 + ul, h3 + ol {
	margin-top: 15px; 
	@include bp(lg) { margin-top: 20px; }
}

p strong,
li strong { font-weight: 700; }

a {
	position: relative;
	color: $color-grey-2;
	text-decoration: underline;
	transition: .2s ease color;
}

a:focus,
a:active,
a:hover { 
	text-decoration: none;
}

a[type="tel"] { color: inherit; }

img, svg {
	height: auto;
	max-width: 100%;
}

.imgrounded img,
.imgrounded svg {
	border-radius: 50%;
}

.nowrap { white-space: nowrap; }



/******************************************
	Text
******************************************/

h1, .h1 {
	font-family: $font-headline;
	color: $color-white;
	font-size: 24px;
	line-height: 1.4;
	font-weight: 400;
	margin-bottom: 0;
	//text-transform: uppercase;
	// hyphens: auto;
	//white-space: nowrap;
	text-align: center;
	text-shadow: 0 0 30px rgba($color-grey-2, .65);
	
	@include bp(xs) { font-size: 32px; }
	@include bp(md) { font-size: 56px; }
	@include bp(xl) { font-size: 64px; }
	@include bp(xxl) { font-size: 72px; }
	
	small {
		margin-bottom: 5px !important;
		display: block;
		font-weight: 400;
		font-size: 60%;
	}
	
	.light { font-weight: 400; }
}

h2, .h2 {
	position: relative;
	font-family: $font-headline;
	font-size: 24px;
	line-height: 1.3;
	font-weight: 700;
	letter-spacing: 0.5px;
	margin: 0 0 15px;
	color: $color-grey-2;
	//text-transform: uppercase;
	
	@include bp(sm) { font-size: 32px; }
	@include bp(md) { margin-bottom: 24px; }
	// @include bp(lg) { font-size: 32px; }
	// @include bp(xl) { font-size: 38px; margin: 0 0 35px; }
	// @include bp(xxl) { font-size: 42px; }
	
	p:not(.heading--subtitle, .h1) + &, ul + &, ol + &  {
		margin: 30px 0 15px;
		@include bp(md) { margin: 50px 0 25px; }
	}
	
	small {
		margin-bottom: 7px !important;
		display: block;
		font-weight: 400 !important;
		font-size: 11px;
		font-family: $font-body;
		text-transform: uppercase;
		letter-spacing: 2px;
		color: $color-grey-2;
		
		@include bp(md) { font-size: 13px; }
		
		.dash {
			display: inline-block;
			width: 32px;
			height: 1px;
			background-color: $color-grey-2;
			vertical-align: 3px;
			margin-inline: 5px;
		}
	}
}

h3, .h3 {
	font-family: $font-headline;
	color: $color-grey-2;
	font-size: 21px;
	line-height: 1.4;
	font-weight: 500;
	letter-spacing: 0.5px;
	margin: 0 0 15px; 	
	
	@include bp(sm) { font-size: 21px; }
	@include bp(md) { font-size: 24px; margin: 0 0 20px; }
	@include bp(lg) { font-size: 26px; }
	@include bp(xl) { font-size: 30px; margin: 0 0 30px; }
	@include bp(xxl) { font-size: 32px; }  
	
	p:not(.heading--subtitle, .h1) + &, ul + &, ol + &  {
		margin: 30px 0 15px;
		@include bp(md) { margin: 40px 0 20px; }
	}
	
	small {
		text-transform: uppercase;
		font-weight: 600;
		letter-spacing: 1px;
		font-size: 16px;
		padding-bottom: 0;
		display: block;
	}
}

h4, .h4 {
	font-family: $font-body;
	color: $color-grey-2;
	font-size: 14px;
	line-height: 1.5;
	font-weight: 400;
	margin: 0 0 15px; 	
	//text-transform: uppercase;
	letter-spacing: 1px;
	
	@include bp(md) { font-size: 20px; }
	//@include bp(xl) { font-size: 17px; }
	
	p:not(.heading--subtitle, .h1) + &, ul + &, ol + &  {
		margin: 30px 0 15px;
		@include bp(md) { margin: 40px 0 20px; }
	}
}