/******************************************
	Color Classes
******************************************/

$color-black: black;
$color-white: white;

$color-grey-1: #353535;
$color-grey-2: #777;


/******************************************
	Fonts
******************************************/

$font-headline: "Sanuk Big", sans-serif;
$font-body: 'Sanuk Big', serif;



/******************************************
	Max Width with 20px "margin" left and right of the screen
******************************************/

$max-width: calc(960px - 60px);
$md-max-width: calc(100vw - 80px);
$lg-max-width: calc(100vw - 140px);
$xxl-max-width: 1440px;



/******************************************
	Shadows
******************************************/

$shadow-lg: 0 10px 30px rgba($color-black, .15);
$shadow-xl: 0 10px 50px rgba($color-black, .15);

.shadow-lg { box-shadow: $shadow-lg; }
.shadow-xl { box-shadow: $shadow-xl; }