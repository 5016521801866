/******************************************
	Sanuk Big
******************************************/

@font-face{
    font-family: "Sanuk Big";
	font-weight: 400;
    src: url("../fonts/5178e0ad-71b4-42ae-998a-edf7e9daac40.eot?#iefix");
    src: url("../fonts/5178e0ad-71b4-42ae-998a-edf7e9daac40.eot?#iefix") format("eot"),
		url("../fonts/96826222-6453-4b5c-ad4d-1c9482f1ef9d.woff2") format("woff2"),
		url("../fonts/47155636-2b0b-4e54-860c-d1816512dc50.woff") format("woff"),
		url("../fonts/a8ce7f17-35b3-4789-bc96-7cefc47af7d3.ttf") format("truetype");
}
