/******************************************
	Main
******************************************/

.main {
	display: flex;
	//flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;
	transition: .65s ease opacity;

	&--faded { opacity: 0; }

	&--legal {
		display: block;
		margin: 0 auto;
		padding: 75px 15px 30px;
		max-width: 640px;
		height: auto;
		opacity: 0;

		h1 {
			margin-bottom: 30px;
			text-align: left;
		}
	}

	&--loaded { opacity: 1 !important; }
}



/******************************************
	Logo
******************************************/

.logo {
	position: absolute;
	top: 60px;
	opacity: 0;
	transition: 1s ease opacity;
	transition-delay: 3s;

	&.loaded { opacity: 1; }

	@include bp(md) {
		left: 60px;
		position: fixed;
	}

	img {
		width: 160px;
		height: auto;
		//margin: 0 auto;
	}
}



/******************************************
	Title
******************************************/

.title {
	transition: .3s ease-in-out opacity;
	transition-delay: .75s;

	@include bp(md) {  position: fixed; }

	&--disabled {
		opacity: 0;
		transition-delay: 0s;
	}

	&__line { display: block; }
}



/******************************************
	Nav
******************************************/

.nav {
	position: absolute;
	bottom: 60px;
	opacity: 0;
	transition: 1s ease opacity;
	transition-delay: 3s;

	&.loaded { opacity: 1; }

	@include bp(md) { position: unset; }
	
	ul {
		list-style: none;
		padding: 0;
		text-align: center;

		li {
			margin-top: 30px;
			transform: translateY(20px);
			transition: .7s ease all;
			opacity: 0;

			@include bp(md) {
				position: fixed;
				margin-top: 0;
				opacity: 1;
				z-index: 2000;
			}

			a {
				color: $color-white;
				text-decoration: none;
				font-size: 17px;
				font-weight: 400;
				transition: .5s ease color;
				pointer-events: none;

				@include bp(md) {
					font-size: 18px;
					pointer-events: unset;
					color: $color-grey-2;
				
					&:hover { color: $color-white; }
				}
			}

			&.email {
				transition-delay: .3s;

				@include bp(md) {
					top: 40px;
					right: 60px;
				}
			}

			&.phone {
				transition-delay: .2s;

				@include bp(md) {
					bottom: 60px;
					right: 60px;
				}
			}

			&.instagram {
				transition-delay: .1s;

				@include bp(md) {
					bottom: 60px;
					left: 60px;
				}
			}

			&.legal {
				@include bp(md) {
					position: absolute;
					top: 100vh;
					left: 0;
					width: 100vw;
					padding-bottom: 40px;
					z-index: 1000;
				}

				a {
					font-size: 80%;
					display: inline-block;
					margin-inline: 5px;
					color: $color-grey-2;

					@include bp(md) {
						margin-inline: 15px;
						
						&:hover { color: $color-white; }
					}
				}
			}
		}
	}

	&--active {
		ul {
			li {
				transform: translateY(0);
				opacity: 1;

				a { pointer-events: unset; }
			}
		}
	}
}

.burger {
	position: relative;;
	width: 36px;
	height: 12px;
	margin: 30px auto 0;

	@include bp(md) { display: none; }


	&:before, &:after {
		content: '';
		display: block;
		position: absolute;
		left: 0;
		width: 100%;
		height: 1px;
		background-color: $color-grey-2;
		transition: .2s ease-in-out all;
	}

	&:before { top: 0; }
	&:after { bottom: 0; }

	&--active {
		&:before {
			transform: rotate(45deg);
			top: 50%;
		}

		&:after {
			transform: rotate(-45deg);
			top: 50%;
			bottom: unset;
		}
	}
}


.close {
	position: absolute;
	top: 15px;
	left: 15px;
	width: 36px;
	height: 36px;
	opacity: 0;
	transition: .35s ease all;

	&:before, &:after {
		content: '';
		display: block;
		position: absolute;
		left: 0;
		top: 50%;
		width: 100%;
		height: 1px;
		background-color: $color-grey-2;
		transition: 1s ease-in-out all;
	}

	&:before { transform: rotate(45deg); }
	&:after { transform: rotate(-45deg); }

	&:hover {
		&:before, &:after { background-color: $color-white; }
	}

	&--loaded { opacity: 1 !important; }
}